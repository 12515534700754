import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import axios from "axios";

const { v4: uuid } = require("uuid");

const Input = styled("input")({
  display: "none",
});

const HeaderContainer = styled("div")({
  textAlign: "center",
  marginTop: "50px",
});

const HeaderText = styled(Typography)({
  fontSize: "24px",
  marginBottom: "16px",
});

const HeaderButton = styled(IconButton)({
  fontSize: "16px",
  padding: "12px 24px",
  borderRadius: "25px",
  backgroundColor: "#3f51b5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#2c387e",
  },
});

const WarningText = styled(Typography)({
  fontSize: "16px",
  color: "red",
  marginBottom: "16px",
});

const Logo = styled("img")({
  maxWidth: "200px",
  height: "auto",
  marginBottom: "24px",
});

export function Header() {
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const handleUpload = useCallback(
    async (binaryData) => {
      if (file) {
        setUploading(true);

        const id = uuid();

        await axios(
          "https://6brhenypaj.execute-api.us-east-1.amazonaws.com/upload-and-process-image",
          {
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: new URLSearchParams({
              id: id,
              key: `${id}.${file.type.split("/")[1]}`,
              image: binaryData,
              type: file.type,
              email,
              text,
            }),
          }
        ).then((res) => {
          console.log(res);
        });

        setUploading(false);
        setFile(null);
        setEmail("");
        setText("");
        setUploadedImageUrl(
          `https://public-uploaded-images.s3.amazonaws.com/${id}`
        );
      }
    },
    [file, email, text]
  );

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const data = reader.result;
      handleUpload(data);
    };
    reader.readAsDataURL(file);
  }, [handleUpload, file]);

  if (uploadedImageUrl) {
    return (
      <HeaderContainer>
        <Logo
          src="https://sw-ml-static-items.s3.amazonaws.com/RGH_chef_man_a8dbe981-a972-4549-9bfd-db0569eb2b99.png"
          alt="Logo"
        />
        <HeaderText variant="h4">
          Sua imagem foi enviada com sucesso! Aguarde que em breve você receberá
          a receita no seu email!
        </HeaderText>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <Logo
        src="https://sw-ml-static-items.s3.amazonaws.com/RGH_chef_man_a8dbe981-a972-4549-9bfd-db0569eb2b99.png"
        alt="Logo"
      />
      {uploading ? (
        <CircularProgress />
      ) : (
        <>
          <HeaderText variant="h4">
            Mande uma foto dos seus ingredientes que eu preparo uma receita para
            você!
          </HeaderText>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <label htmlFor="icon-button-file">
              <Input
                disabled={uploading || !email || !email.includes("@")}
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  setFile(file);
                }}
              />
              <HeaderButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                {!email || !email.includes("@") ? (
                  "Preencha seu email!"
                ) : (
                  <PhotoCamera />
                )}
              </HeaderButton>
            </label>
          </Stack>

          <div style={{ margin: "30px 30%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <TextField
                id="outlined-basic"
                label="Email"
                variant="filled"
                style={{ width: "100%" }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
          </div>

          <WarningText>
            Digite seu email aqui para mandar a imagem que eu te mando a
            receita! Só selecione a imagem depois de preencher o campo de email.
          </WarningText>

          <WarningText>
            Digite abaixo instruções caso queira para a sua receita!
          </WarningText>

          <div style={{ margin: "30px 60px" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <TextField
                id="outlined-basic"
                label="Instruções"
                variant="filled"
                style={{ width: "100%" }}
                onChange={(e) => setText(e.target.value)}
              />
            </Stack>
          </div>
        </>
      )}
    </HeaderContainer>
  );
}
